<template>
  <div>
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <div>
        <div class="heading-sec">
          <div class="row">
            <div class="col-md-4 column">
              <div class="heading-profile">
                <h3>Inquiry Details</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="table-area">
                  <div class="widget-title">
                    <div class="float-right"></div>
                  </div>
                  <div class="form-section">
                    <div class="form-detail pt-3">
                      <b-row>
                        <b-col md="4">
                          <b-input
                            placeholder="Full Name"
                            v-model="inquiry.name"
                            readonly
                          ></b-input>
                        </b-col>
                        <b-col md="4">
                          <b-input
                            type="email"
                            placeholder="Email"
                            v-model="inquiry.email"
                            readonly
                          ></b-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="4">
                          <b-input
                            type="number"
                            placeholder="Mobile No"
                            class="mt-4"
                            v-model="inquiry.mobile"
                            maxlength="10"
                            readonly
                          ></b-input>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="seeking-section pt-4">
                      <div class="font-14 title-section pb-2 font-weight-bold">
                        Seeking for:
                      </div>
                      <div class="font-14 title-section pb-2 ">
                        {{ inquiry.seekingFor }}
                      </div>
                    </div>
                    <div class="ocassion-section pt-3">
                      <div class="font-14 ocassion-title pb-2 font-weight-bold">
                        Occasion:
                      </div>
                      <div class="font-14 ocassion-title pb-2 ">
                        {{ inquiry.occasion }}
                      </div>
                    </div>
                    <div class="location-section pt-3">
                      <div class="font-14 title-section pb-2 font-weight-bold">
                        Location Preference:
                      </div>
                      <div class="font-14 title-section pb-2 ">
                        {{ inquiry.locationPreference }}
                      </div>
                    </div>
                    <div class="font-14 function-date pt-3">
                      <div class="pb-2 font-weight-bold">
                        Function Date:
                      </div>
                      <div>
                        <b-row>
                          <b-col md="4">
                            <div>
                              <b-input v-model="inquiry.fromDate" readonly />
                            </div>
                          </b-col>

                          <b-col md="4">
                            <div>
                              <b-input v-model="inquiry.toDate" readonly />
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <div class="night-section pt-3">
                      <div class="font-14 pb-2 font-weight-bold">
                        No. of Nights:
                      </div>
                      <div>
                        <b-row>
                          <b-col md="4">
                            <div>
                              <b-input v-model="inquiry.noOfNights" readonly></b-input>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <div class="remark-section pt-3">
                      <div class="font-14 pb-2 font-weight-bold">
                        Remarks:
                      </div>
                      <div>
                        <b-row>
                          <b-col md="8">
                            <b-input v-model="inquiry.remark" readonly></b-input>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import headerCustom from "@/components/Header.vue";
import apiservice from "@/service/apiservice";
import moment from "moment";

export default {
  components: {
    headerCustom
  },
  data() {
    return {
      inquiry: {},
      id: ""
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.viewInquiryDetail();
  },
  methods: {
    viewInquiryDetail() {
      apiservice.getOneInquiry(this.id, data => {
        if (data.code === 200) {
          this.inquiry = data.data;
          this.inquiry.fromDate = moment(this.inquiry.fromDate).format("MMMM DD YYYY");
          this.inquiry.toDate = moment(this.inquiry.toDate).format("MMMM DD YYYY");
        } else {
        }
      });
    }
  }
};
</script>

<style></style>
